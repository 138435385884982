import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "status": "published",
  "path": "/plex-setup/",
  "heroImage": "plex_logo.png",
  "title": "Installing Plex on my Home Server",
  "description": "Home Media Player",
  "date": "2022-09-03T00:00:00.000Z",
  "tags": ["home", "code"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`If you're a movie and TV show lover like me, you know how frustrating it can be to keep track of all your media across different streaming services and devices. Luckily, there's a solution that can simplify your media collection and make it easier to watch your favorite shows and movies hassle-free: Plex on Proxmox.`}</p>
    <p>{`Proxmox is a popular virtualization platform that allows you to run multiple operating systems on a single physical server. And when you combine it with Plex, you get a powerful media server setup that can store, organize, and stream all your media from a single location.`}</p>
    <p>{`In this blog post, I'll walk you through the steps I took to install Plex on my home server using Proxmox. I'll also share some tips and tricks to help you get the most out of your media setup, whether you're a hardcore movie buff, a TV show addict, or just someone looking to simplify their media collection.`}</p>
    <p>{`So, get ready to dive into the world of virtualization and media streaming, and let's get your Plex setup up and running on your home server. Who knows, you might even impress your friends with your newfound tech skills (or at least impress them with your movie collection).`}</p>
    <h2>{`Install Plex onto a Proxmox Container`}</h2>
    <ol>
      <li parentName="ol">{`Navigate to: the `}<strong parentName="li">{`proxmox`}</strong>{` node`}</li>
      <li parentName="ol">{`Navigate to: `}<strong parentName="li">{`Console`}</strong>{` and run the following command to download the 20.04 Ubuntu Template:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`pveam download `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`local`}</span>{` ubuntu-20.04-standard_20.04-1_amd64.tar.gz`}</code></pre></div>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Click the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Create CT`}</code>{` button`}</p>
        <h3 parentName="li">{`General`}</h3>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1024px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "71.09375%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABYElEQVQ4y61Ty07DMBDM/98QJ/gGrpyQ4EYrLkgcKjhUVKRJExqSOE7S5uEMHquOUqtIRbDSKF7v7Ox4q3rLKMdbkOEzLRAmApmQqKoKZVn+Gmku4d08x7h63CBJvhBFEaQuFEUBKaX5Ts+20Z452IVX1zX6rsMwDBAiR57n6Psenb6zaNsW5FFkv9+bvGmaIw7BmhFkkSGEMI2nBOlcyhJqAHLNi/Vr6GjKpY6306qczAIJbFZKmdyCeVuXGJrKDN6Vet+bjeGzRjHy2OvRVZZl4yQ3uArGIhCYLxM8vaf4SGsMSvPdoRS0u2B0h11aIUId8ttFiutZpH/ACLOVNHe2ZoOinutkena/p5zbwQw69ezFFCNx0kg3Sh3giI0cOnRFfoqTQx23R4J/xfhk/GOMDk896RxH43NNzv1qh0EQIAxDbLdbxHF8Fvif930fwdrH/DXA3csaF/crXD6s8A1Zd0O5EQ1VVwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "General Tab",
              "title": "General Tab",
              "src": "/static/e505c971ea908012ad34e5a2449eb05d/2bef9/ct_1_general_tab.png",
              "srcSet": ["/static/e505c971ea908012ad34e5a2449eb05d/6f3f2/ct_1_general_tab.png 256w", "/static/e505c971ea908012ad34e5a2449eb05d/01e7c/ct_1_general_tab.png 512w", "/static/e505c971ea908012ad34e5a2449eb05d/2bef9/ct_1_general_tab.png 1024w", "/static/e505c971ea908012ad34e5a2449eb05d/c65fa/ct_1_general_tab.png 1434w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Node:`}</strong>{` proxmox`}</li>
          <li parentName="ul"><strong parentName="li">{`VM ID:`}</strong>{` 110`}</li>
          <li parentName="ul"><strong parentName="li">{`Name:`}</strong>{` Plex`}</li>
          <li parentName="ul">{`Set Password`}</li>
        </ul>
        <hr parentName="li"></hr>
        <h3 parentName="li">{`Template`}</h3>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1024px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "70.703125%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA80lEQVQ4y9WRMU/DMBCF/euZurGw9wd0Rh06wQwzElFD0zohtezYURLbyaMXFKuURi0oS5/0+Z3O9vNJZnyvwHOJt53EayyQCYXSaGhjYP6IkAWYEAL7/BOLlxQPT1tEuxxKij5Qa42iKH74UFNAWZa/YLRUVQWS0d8Hm6bpqeu6d2stzqnrugDJew9Gl4ZArQ2klFBKBacHnHMhYCyY1LYt2GlzTMfTnCNMeNy4dOGqCScPxAS6scBLv3ct4ZcxofoJaaFk8v/gfQt3wB9qax1YkiQgsiwD5xxpmo4y7JPHcYzt5gPz5zXuHt8xW0a4X0X4AtwJSXQ6PLVQAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Template Tab",
              "title": "Template Tab",
              "src": "/static/08a6bfd211d1a1488e0e34f120e50b5f/2bef9/ct_2_template_tab.png",
              "srcSet": ["/static/08a6bfd211d1a1488e0e34f120e50b5f/6f3f2/ct_2_template_tab.png 256w", "/static/08a6bfd211d1a1488e0e34f120e50b5f/01e7c/ct_2_template_tab.png 512w", "/static/08a6bfd211d1a1488e0e34f120e50b5f/2bef9/ct_2_template_tab.png 1024w", "/static/08a6bfd211d1a1488e0e34f120e50b5f/c65fa/ct_2_template_tab.png 1434w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Storage:`}</strong>{` local`}</li>
          <li parentName="ul"><strong parentName="li">{`Templage:`}</strong>{` ubuntu-20.04-standard_20.04-1_amd64.tar.gz`}</li>
        </ul>
        <hr parentName="li"></hr>
        <h3 parentName="li">{`Disks`}</h3>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1024px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "70.703125%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABWklEQVQ4y52Sy07DMBBF/fMsEIuKf0Cs+ABYtGKJhAS7bipRaJrGad7vl52LJ9RV2oRCa+koI3vmzh07bOvH4F4E+lpuBMOJYHkx0iRBsiNN03/hhzGY63nYOg4c14Pv2Hiau3h4t5FEAXzfh6PO4jju0A0oJoEsywawxcbH29LFnGdYWDEylViWBdq2RRAECMMQQgjUdd1B+6dgZd2gEhK1aBVy36kois5FVVVomqaDYiqSUh5ADQmKmVTBfqlk7USL6GQdUzOKqdhT10Xj6wlon4mdoNLqupOzPM+7hDEXZVl2otr5YGTRc6jH0Ydjq188tkYFjwt/Exl9lHMFTzU4cKgT+oKXLNZKcbBxrsPByFlZI6/V71D9cKmzvcO7V46b2RrXUwOTZxONoFeWg5/3mP4V9WGmxbEyLRgmx3rDYdv2n3CualYGLNPA/csXrh4/MJkucTv7xDcehUNgvb0anAAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Disks Tab",
              "title": "Disks Tab",
              "src": "/static/f97bae249147fe95a116c5cf88bb0a7d/2bef9/ct_3_disks_tab.png",
              "srcSet": ["/static/f97bae249147fe95a116c5cf88bb0a7d/6f3f2/ct_3_disks_tab.png 256w", "/static/f97bae249147fe95a116c5cf88bb0a7d/01e7c/ct_3_disks_tab.png 512w", "/static/f97bae249147fe95a116c5cf88bb0a7d/2bef9/ct_3_disks_tab.png 1024w", "/static/f97bae249147fe95a116c5cf88bb0a7d/c65fa/ct_3_disks_tab.png 1434w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Disk size (GB):`}</strong>{` 20`}</li>
          <li parentName="ul">{`Click `}<strong parentName="li">{`Next:`}</strong></li>
        </ul>
        <hr parentName="li"></hr>
        <h3 parentName="li">{`CPU`}</h3>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1024px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "70.3125%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABE0lEQVQ4y9WTzU7DMBCE8+o8AXfgPbhxqcRbgNrSgJ3UKXZ+HScZPAajlPJTyomVPo29Ge2OlCSRhYFQ2mPwuNUodImqqpA/lzDl6/kn6jdVO41EKYUsz0EVQmJXKGzkFpe3T7hLM6g8gzEGWuugpV/CM7Wu6z3YS9q2hXMO4zii6zq4vof1NJ0Naq0NTNMUoI/Mz8MwBO29PwmR/XQ24xY+cK4PSqxf1DTNu48LmJJBoocwXBKns+K2mGZOTMPincM+ekLCuemY+swXewyzN/AYvvKymPrXCb9L/Q8H/oWDgSfkOui4+VuOX/8pbxvhPmFgwjRNQXL/P0spj0IIgdV6jXTzgKvFEmfX9zi/WeJiscILBetI7NF5M/gAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "CPU Tab",
              "title": "CPU Tab",
              "src": "/static/bfb70645c86a89308d24ab1bbae1b6ed/2bef9/ct_4_cpu_tab.png",
              "srcSet": ["/static/bfb70645c86a89308d24ab1bbae1b6ed/6f3f2/ct_4_cpu_tab.png 256w", "/static/bfb70645c86a89308d24ab1bbae1b6ed/01e7c/ct_4_cpu_tab.png 512w", "/static/bfb70645c86a89308d24ab1bbae1b6ed/2bef9/ct_4_cpu_tab.png 1024w", "/static/bfb70645c86a89308d24ab1bbae1b6ed/07a9c/ct_4_cpu_tab.png 1440w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Cores:`}</strong>{` 4`}</li>
          <li parentName="ul">
            <h4 parentName="li">{`Menory`}</h4>
          </li>
        </ul>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1024px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "70.703125%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA/ElEQVQ4y9WTzU6FMBCF+/4L4+I+hS/gSk3cunUvXlJsLS39AcL/kekVveYmSAwbJ/naUmZOT9oMk8ZBaAeaubIw1qMsy5kAYTxe3x2cD3EvhBA5/b+EapkxBlprFEUBKSWstchzjdIVuH2WODxyJG85TK6g51zKW0TrukZVVT9gNHjv0DQNuq6DUgp930eGT9q2xTiOmKZplWEYwEiIRKmANrz3X+sFEt8SVMfWEuZDI6f1ujuK6PC3xPOCZb48ePp2uLsgdoh/Jrj1Dje/MnaM6JCGv7A4O/+mBmCcc2RZFluOenkLQggcjykynuLmKcX1fYKruxccHhJ8APCLScn/k2dxAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Memory Tab",
              "title": "Memory Tab",
              "src": "/static/2730945e717779ace0a03c652ef7a8d2/2bef9/ct_5_memory_tab.png",
              "srcSet": ["/static/2730945e717779ace0a03c652ef7a8d2/6f3f2/ct_5_memory_tab.png 256w", "/static/2730945e717779ace0a03c652ef7a8d2/01e7c/ct_5_memory_tab.png 512w", "/static/2730945e717779ace0a03c652ef7a8d2/2bef9/ct_5_memory_tab.png 1024w", "/static/2730945e717779ace0a03c652ef7a8d2/c65fa/ct_5_memory_tab.png 1434w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Memory (MiB):`}</strong>{` 4096`}</li>
          <li parentName="ul"><strong parentName="li">{`Swap (MiB):`}</strong>{` 1024`}</li>
        </ul>
        <hr parentName="li"></hr>
        <h3 parentName="li">{`Network`}</h3>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1024px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "70.703125%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABSklEQVQ4y62TTW6DMBCFuXy3ldo79A5dRF100yN01yg0UAjB2NhA+EleeSMZUZQm6o+llzEzw/fGThKkqkSSG6TKIMo0lLGonIO1VuScxa6w2OwMtCnl2Y31c1K6RKCUwn6/B2OaphLzPEdRFGN+jHmG1WuG++cY648c1ujRyKGqKtR1LeLeK+BH27Y4nU4oyxJN06Dv+0ld10msKicmrPuXh2GQ2vF4FPE54LFYZIF7OrI4h7KReRpyb4zBdruVkzDvwTSXCenKxXvwLy2nZE1rLQAaE8Yca18mJJAQNhLM/RzmgYTwyB7O3sPhICLIDzFNyISPvM+lWCOI9+2vYT7ZBGTiJ+uc2VwT8Fqj1zWzs8BLk31nfhH4Oy2A/7EEOP+2/ir5HUZRhDiOkWUZkiSR//M1sS8MQ8TROx5eNrh5fMPtao27pzU+AVezQ3zyp/kJAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Network Tab",
              "title": "Network Tab",
              "src": "/static/d270ea55cfcc89f9dec6c0b6a3c05c85/2bef9/ct_6_network_tab.png",
              "srcSet": ["/static/d270ea55cfcc89f9dec6c0b6a3c05c85/6f3f2/ct_6_network_tab.png 256w", "/static/d270ea55cfcc89f9dec6c0b6a3c05c85/01e7c/ct_6_network_tab.png 512w", "/static/d270ea55cfcc89f9dec6c0b6a3c05c85/2bef9/ct_6_network_tab.png 1024w", "/static/d270ea55cfcc89f9dec6c0b6a3c05c85/85e74/ct_6_network_tab.png 1436w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`IPV4:`}</strong>{` 192.168.68.110/24`}</li>
          <li parentName="ul"><strong parentName="li">{`Gateway:`}</strong>{` 192.168.68.1`}</li>
        </ul>
        <hr parentName="li"></hr>
        <h3 parentName="li">{`DNS`}</h3>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1024px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "70.703125%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA9ElEQVQ4y9WTzWqEMBSF8/Z9gS676jvMonTTRekTlFmWjv1BjUkmMYr/px5BURhoBtzMhY/rvTmeewlEJNoiVhbMP9JCWwfvPfI8X2DtfT5qHFIzni+9LfrsILTWyLIMZsxJksAYA/aYlVKQUkKxzlIc3lM8HyWsljhbi7IsURTFBjFv0zTNJHDOwY7iuq7Rtu3UJ33fYxiGifX3uu66DqKqqsmIBU04hUNoRiH7JCSoFwiM9UaXWAznIuSHOV8atjEMIdgQO8SNGYbe4b/sueGyKQ3puhctXwrf77XEcYwoivD7/YXHtxPunj5w//KJh9cT/gBLQEnZ2ePRigAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "DNS Tab",
              "title": "DNS Tab",
              "src": "/static/ce347512ab1a4c391b00470f024fde05/2bef9/ct_7_dns_tab.png",
              "srcSet": ["/static/ce347512ab1a4c391b00470f024fde05/6f3f2/ct_7_dns_tab.png 256w", "/static/ce347512ab1a4c391b00470f024fde05/01e7c/ct_7_dns_tab.png 512w", "/static/ce347512ab1a4c391b00470f024fde05/2bef9/ct_7_dns_tab.png 1024w", "/static/ce347512ab1a4c391b00470f024fde05/85e74/ct_7_dns_tab.png 1436w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <ul parentName="li">
          <li parentName="ul">{`Click `}<strong parentName="li">{`Next`}</strong></li>
        </ul>
        <hr parentName="li"></hr>
        <h3 parentName="li">{`Confirm`}</h3>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1024px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "70.703125%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABdklEQVQ4y51T206FMBDk633V+At+hQ9GffMbjFGRwwnXQmkpd0emZvGYmCg+TLa7XabbmRKkqkFSamRrjAsNpQ2stTDGbPjM7RoN6sYgzDTyysC1rd8TqNogKMsSWZ6DMUkSKKX8WmK+7nGdpimaWuEpznFxd8D9Y4G5dzC2Rdt+IdDWobIdGjdCuwHW9ej7DsuyYJ7nH/G+7i3L9xr7p2lCoKoKeVFiXIvDOKHrejRNg2EYfCOb9iCo6xrH49FPRQJqwWvuIRzHcYMnpD5CQBNI2HWd18Q5t0WCB4pekvNbIQ601iiKYp2w90U2cmIxhgdKZC/rpzVGHr5NyKYwDP0mhSUh3eYhbBa3iWrVm7kMIIacXjtgE00gGQtslPd3qs1f4TUkqQhMXaghJ/0XIcniOPZTcnwxQyb+1eFTkFAco1O8Lgk5JfO98IRiPcH14v+CZXv9exHwDWZZtoH5b+AriKI3RIcYVw8Rzq6fcX7zgsvbV3wA2kM4cgoVMsQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Confirm Tab",
              "title": "Confirm Tab",
              "src": "/static/0597dd7a5880f3348c54ab56a3cf163e/2bef9/ct_8_confirm_tab.png",
              "srcSet": ["/static/0597dd7a5880f3348c54ab56a3cf163e/6f3f2/ct_8_confirm_tab.png 256w", "/static/0597dd7a5880f3348c54ab56a3cf163e/01e7c/ct_8_confirm_tab.png 512w", "/static/0597dd7a5880f3348c54ab56a3cf163e/2bef9/ct_8_confirm_tab.png 1024w", "/static/0597dd7a5880f3348c54ab56a3cf163e/c65fa/ct_8_confirm_tab.png 1434w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
        <ul parentName="li">
          <li parentName="ul">{`Click `}<strong parentName="li">{`Finish:`}</strong></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Navigate to: `}<strong parentName="p">{`110 (Plex)`}</strong>{` > `}<em parentName="p">{`Console`}</em></p>
      </li>
    </ol>
    <p>{`Run the following command to update the container:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` update`}</code></pre></div>
    <p>{`Run the following command upgrade the container:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` upgrade -y`}</code></pre></div>
    <h3>{`Mount Shared External Hard Drive to Plex Container`}</h3>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">
        <p parentName="li">{`Navigate to: the `}<strong parentName="p">{`proxmox`}</strong>{` node:`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Navigate to: `}<strong parentName="p">{`>`}{`_`}{` Shell`}</strong></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a directory in the container to hold the Plex content with the following command:`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}><span parentName="code" {...{
                "className": "token function"
              }}>{`mkdir`}</span>{` /mnt/plex`}</code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Edit the fstab file with the following command:`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}><span parentName="code" {...{
                "className": "token function"
              }}>{`nano`}</span>{` /etc/fstab`}</code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the following line in the file (Must have SMB Ver 3 enabled on Synology)`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}>{`//192.168.68.110/video /mnt/plex cifs `}<span parentName="code" {...{
                "className": "token assign-left variable"
              }}>{`username`}</span><span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span><span parentName="code" {...{
                "className": "token operator"
              }}>{`<`}</span>{`NAS Username`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`>`}</span>{`,password`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span><span parentName="code" {...{
                "className": "token operator"
              }}>{`<`}</span>{`NAS Password`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`>`}</span>{`,vers`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span><span parentName="code" {...{
                "className": "token number"
              }}>{`3.0`}</span>{`,iocharset`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{`utf8,noperm `}<span parentName="code" {...{
                "className": "token number"
              }}>{`0`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`0`}</span></code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Mount the drive`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}><span parentName="code" {...{
                "className": "token function"
              }}>{`mount`}</span>{` -a`}</code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Check to see if mount is present with the following command:`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}><span parentName="code" {...{
                "className": "token builtin class-name"
              }}>{`cd`}</span>{` /mnt/plex
`}<span parentName="code" {...{
                "className": "token function"
              }}>{`ls`}</span></code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`CD into the following directory and edit the `}<strong parentName="p">{`110.conf`}</strong>{` file (Start container for 110.conf to exist)`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}><span parentName="code" {...{
                "className": "token builtin class-name"
              }}>{`cd`}</span>{` /etc/pve/lxc
`}<span parentName="code" {...{
                "className": "token function"
              }}>{`nano`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`110`}</span>{`.conf`}</code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the following line into the `}<strong parentName="p">{`110.conf`}</strong>{` file`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}>{`mp0: /mnt/plex,mp`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{`/mnt/`}</code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Navigate to: `}<strong parentName="p">{`110 (Plex)`}</strong></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Navigate to: `}<em parentName="p">{`Console`}</em></p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}><span parentName="code" {...{
                "className": "token function"
              }}>{`reboot`}</span></code></pre></div>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add Mount Point to 110 Plex`}</p>
        <ol parentName="li">
          <li parentName="ol">{`Navigate to: 110 Plex > Resources`}</li>
          <li parentName="ol">{`Click `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`Add`}</code>{` Mount Point`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Download the Plex installation file.`}</p>
      </li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`wget`}</span>{` https://downloads.plex.tv/plex-media-server-new/1.27.2.5929-a806c5905/debian/plexmediaserver_1.27.2.5929-a806c5905_amd64.deb`}</code></pre></div>
    <ol {...{
      "start": 17
    }}>
      <li parentName="ol">{`Unpackage the installation file:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`dpkg -i plexmediaserver*.deb`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`systemctl `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`enable`}</span>{` plexmediaserver.service`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`systemctl start plexmediaserver.service`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /etc/apt/sources.list.d/plexmediaserver.list`}</code></pre></div>
    <ol {...{
      "start": 18
    }}>
      <li parentName="ol">{`Uncomment the line `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`deb https://downloads.plex.tv/repo/deb/ public main`}</code>{` in the file`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`deb https://downloads.plex.tv/repo/deb/ public main`}</code></pre></div>
    <ol {...{
      "start": 19
    }}>
      <li parentName="ol">{`Go to URL `}<a parentName="li" {...{
          "href": "http://192.168.68.110:32400/web/index.html",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`http://192.168.68.110:32400/web/index.html`}</a></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      